.photoSliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.photoSlider {
  position: relative;
  flex-direction: column;
  gap: var(--usualGap);
  width: 100%;
}

.ellipse {
  width: 10px;
  height: 10px;
  background: var(--Text);
  border-radius: 50%;
  cursor: pointer;
}

.active {
  margin: -2px 0 -2px -2px;
  border: 2px #ff7f2a solid;
}

.photoSliderImg {
  display: none;
}

.sliderskeleton {
  display: none;
  height: 250px;
  width: 100%;
}

.showing {
  display: block;
}

.controlPanel {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
}

.pictureControl {
  display: flex;
  flex-direction: row;
  gap: var(--usualGap);
  padding: 10px;
}

.sideControl {
  position: relative;
  height: 100%;
  width: 20vw;
  background: linear-gradient(0.75turn, transparent, var(--Text));
}

.sideControl::after {
  cursor: pointer;
}

.rightSideControl {
  rotate: 180deg;
}

.sideControlIcon {
  content: '';
  position: absolute;
  rotate: 180deg;
  width: 30px;
  height: 30px;
  mask-image: url(/public/icons/nextIcon.svg);
  -webkit-mask-image: url(/public/icons/nextIcon.svg);
  mask-size: contain;
  -webkit-mask-size: contain;
  background-color: var(--Action);
  opacity: 1;
  top: 50%;
  left: 0px;
  transform: translateY(50%);
  cursor: pointer;
}

.alternativephotoslider {
  display: flex;
  height: 100%;
  gap: var(--usualGap);
  width: 100%;
}

.alternativeselectedimage {
  width: 1000px;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.alternativeimageselector {
  display: flex;
  flex-direction: column;
  gap: 3%;
  width: 20%;
}

.alternativeselectableimage.selected {
  border-color: black;
}

.alternativeselectableimage.loading {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.alternativeselectedimage.loading {
  height: 100%;
  width: 100%;
}

.alternativeimagedetails {
  width: 80%;
}

.alternativeselectableimage {
  object-fit: cover;
  width: 100%;
  cursor: pointer;
  border: 2px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  aspect-ratio: 1 / 1.26;
}

.controlPanel,
.photoSliderImg,
.photoDisplay,
.photoSlider {
  width: 100%;
  aspect-ratio: calc(16 / 9);
  object-fit: cover;
  max-width: 1000px;
}

@media screen and (min-width: 1000px) {
  .photoSlider {
    margin-left: 0px;
  }
}

@media (max-width: 800px) {
  .sliderskeleton {
    height: 200px;
  }
}
