/* TODO: Footer muss anders definiert werden, so ist es absolut und nicht responsiv */
.footer {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--Text);
  color: var(--Alternative);
  padding: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-top: auto;
}

.policydescription {
  font-size: 12px;
}
