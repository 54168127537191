.admineditablecontainer {
  position: relative;
  width: calc(100% - 400px);
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.admineditable {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 20px;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.admineditablecomponent {
  width: 100%;
}

.editabletitle {
  margin: 5px 0px;
}

.admineditorwrapper {
  border-radius: 27px;
}

.admineditor {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  width: 100%;
  padding: 20px 60px 20px 20px;
  line-height: 1.2rem;
}

.admintoolbar {
  background: rgba(0, 0, 0, 0.75) !important;
  border: none !important;
  outline: none;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editableinput {
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  width: 100%;
  outline: none;
  color: var(--Text);
  padding: 20px 60px 20px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editablepicturecontainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.editablepicturepart {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
}

.editablepictures {
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-template-rows: repeat(auto-fit, 250px);
}

.addimagecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 2px dashed var(--Text);
  border-radius: 27px;
  cursor: pointer;
}

.addimagecontainer.readonly {
  border: 2px dashed var(--Text50);
  cursor: default;
}

.pictureheader {
  padding: 0px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
