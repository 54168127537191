.analysisui {
  position: fixed;
  top: -100px;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8999;
  transition: top 0.5s;
  -moz-transition: top 0.5s;
  -webkit-transition: top 0.5s;
}

.analysisui.hidden {
  top: -100px;
}

.analysisui.visible {
  top: 100px;
}

.analysis {
  width: 250px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis.error {
  background-color: red;
}

.analysis.success {
  background-color: green;
}
