.contentcontainer {
  height: 100%;
  width: 100%;
}

.maincontent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  margin-top: 220px;
  gap: 40px;
  overflow: hidden;
}

.homecontent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.upcomingcontainer {
  width: 100%;
  flex: 6;
}

.upcomingtitle {
  white-space: nowrap;
  margin-bottom: 40px;
}

.upcomings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding: 0px 5px 0px 0px;
  scrollbar-width: none;
}

.heroSection {
  height: 220px;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
}

.heroSectionHeader {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  width: 50%;
  height: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: white;
}

.heroSectionBackground {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: white;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.3) 95%,
    rgba(0, 0, 0, 0) 100%
  );
}

.heroImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
  object-position: center 55%;
}

.heroSection + .section {
  margin-top: 220px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--lightGray);
}

@media screen and (min-width: 1040px) {
  .homecontent {
    width: 100%;
  }
}

@media (max-width: 1040px) {
  .upcomingcontainer {
    display: none;
  }
}
