.memberGrid {
  display: grid;
  gap: var(--usualGap);
  grid-template-columns: repeat(auto-fit, 150px);
}

@media only screen and (min-width: 530px) {
  .memberGrid {
    grid-template-columns: repeat(auto-fit, 240px);
  }
}
