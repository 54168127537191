.adminnavigation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  border-right: 5px solid var(--Text);
  height: calc(100vh - 80px);
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-y: auto;
}

.adminnavigationoverview {
  width: 100%;
}

.navigationsection {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.navigationcontent {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0px;
}

.sectionheader {
  margin: 0;
  font-weight: normal;
  cursor: pointer;
}

.sectionheader.selected {
  font-weight: 600;
}

.sectionicon {
  margin-top: 2px;
  cursor: pointer;
}

.leftpart,
.rightpart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.navigationchildren {
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.adminnavigationbuttons {
  display: flex;
  width: 100%;
  padding: 10px;
  bottom: 0px;
  position: sticky;
  background-color: white;
  justify-content: center;
}
