.button {
  font-size: 16px;
  font-weight: 700;
  border: none;
  color: var(--Text);
  border-radius: 50px;
  padding: 10px 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--usualGap);
}

.button:active {
  letter-spacing: 2px;
}

.button:hover {
  opacity: 0.8;
}

.button.disabled,
.button.disabled:hover {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.primary {
  background-color: var(--Action);
}

.submitbutton {
  position: relative;
}

.commonbutton {
  background-color: var(--Action);
  color: var(--Text);
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
  font-size: var(--content-one-font-size-desktop);
  font-family: var(--standard-font-family);
  font-weight: 500;
}

.commonbutton.disabled {
  background-color: var(--Action50);
  cursor: default;
}

.commonlink {
  background-color: transparent;
  color: var(--Text);
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 150px;
  font-size: var(--content-one-font-size-desktop);
  font-family: var(--standard-font-family);
  font-weight: 500;
}

.commonlink.disabled {
  opacity: 0.5;
  cursor: default;
}

.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  padding: 10px 20px;
  border-radius: 40px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: none;
}

.overlay.loading {
  display: flex;
  background-color: var(--Action);
}

.overlay.success {
  display: flex;
  background-color: var(--Action);
}

.overlay.error {
  display: flex;
  background-color: red;
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 4px solid var(--Text);
  border-bottom-color: var(--Text);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .commonbutton {
    font-size: var(--content-one-font-size-tablet);
    padding: 10px 20px;
    font-weight: 500;
  }

  .commonlink {
    font-size: var(--content-one-font-size-tablet);
  }

  .loader {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .commonbutton {
    font-size: var(--content-one-font-size-phone);
    padding: 10px 20px;
    font-weight: 500;
  }

  .commonlink {
    font-size: var(--content-one-font-size-phone);
  }
}
