.datenschutz {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
}

.datenschutz > h3 {
  text-align: left;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  line-break: auto;
  -ms-line-break: auto;
  -webkit-line-break: auto;
}

.datenschutz > div > p > ul {
  text-align: left;
}
