.editabletoggle {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.editabletoggle > h3 {
  width: 250px;
}

.admineditablemulticomponent {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: var(--usualGap);
}
