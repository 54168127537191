:root {
  --Text: #10213a;
  --Text80: #10213acc;
  --Text50: #10213a70;
  --Text25: #10213a40;
  --TextAlternative: #214478;
  --Action: #ff7f2a;
  --Action50: #ff7f2a80;
  --Alternative: #ffffff;
  --TextAlternative50: #ffffff80;
  --TextAlternative30: #ffffff4d;
  --heightOfShownMenu: -200px;
  --transitionTime: 500ms;
  --usualGap: 10px;
  --bigGap: 20px;
  --header-one-font-size-desktop: 2.25rem;
  --header-one-font-size-tablet: 1.875rem;
  --header-one-font-size-phone: 1.5rem;
  --header-two-font-size-desktop: 1.75rem;
  --header-two-font-size-tablet: 1.5rem;
  --header-two-font-size-phone: 1.25rem;
  --header-three-font-size-desktop: 1.5rem;
  --header-three-font-size-tablet: 1.375rem;
  --header-three-font-size-phone: 1.25rem;
  --header-four-font-size-desktop: 1.25rem;
  --header-four-font-size-tablet: 1.125rem;
  --header-four-font-size-phone: 1rem;
  --content-one-font-size-desktop: 1.125rem;
  --content-one-font-size-tablet: 1rem;
  --content-one-font-size-phone: 0.875rem;
  --content-two-font-size-desktop: 1rem;
  --content-two-font-size-tablet: 0.875rem;
  --content-two-font-size-phone: 0.75rem;
  --content-three-font-size-desktop: 0.875rem;
  --content-three-font-size-tablet: 0.75rem;
  --content-three-font-size-phone: 0.625rem;
  --content-four-font-size-desktop: 0.75rem;
  --content-four-font-size-tablet: 0.7rem;
  --content-four-font-size-phone: 0.65rem;
  --content-four-font-size-mini: 0.5rem;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--Text);
  min-height: 100vh;
  user-select: none;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.boldText {
  font-weight: 700;
}

.author {
  width: 100%;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  font-style: italic;
  color: var(--TextAlternative);
}

.lightText {
  color: var(--Alternative) !important;
}

.lightBackground {
  background-color: var(--Alternative) !important;
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usualGap {
  gap: var(--usualGap);
}

h1 {
  font-size: var(--header-one-font-size-desktop) !important;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin: 0;
}

h2 {
  font-size: var(--header-two-font-size-desktop) !important;
  font-weight: 700;
  margin: 0 0 5px 0;
}

h3 {
  font-size: var(--header-three-font-size-desktop) !important;
  font-weight: 700;
  margin: 0 0 5px 0;
}

h4 {
  font-size: var(--header-four-font-size-desktop) !important;
  font-weight: 700;
  margin: 0 0 5px 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

p {
  margin: 0 !important;
}

button.c1,
li.c1,
p.c1,
a.c1,
.c1 > * {
  font-size: var(--content-one-font-size-desktop);
}

button.c2,
li.c2,
p.c2,
a.c2,
.c2 > * {
  font-size: var(--content-two-font-size-desktop);
}

button.c3,
li.c3,
p.c3,
a.c3,
.c3 > * {
  font-size: var(--content-three-font-size-desktop);
}

button.c4,
li.c4,
p.c4,
a.c4,
.c4 > * {
  font-size: var(--content-four-font-size-desktop);
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.hrefitalic {
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@media (max-width: 1200px) {
  h1 {
    font-size: var(--header-one-font-size-tablet) !important;
    font-weight: 700;
    letter-spacing: 0.36px;
    margin: 0;
  }

  h2 {
    font-size: var(--header-two-font-size-tablet) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  h3 {
    font-size: var(--header-three-font-size-tablet) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  h4 {
    font-size: var(--header-four-font-size-tablet) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  button.c1,
  li.c1,
  p.c1,
  a.c1,
  .c1 > * {
    font-size: var(--content-one-font-size-tablet);
  }

  button.c2,
  li.c2,
  p.c2,
  a.c2,
  .c2 > * {
    font-size: var(--content-two-font-size-tablet);
  }

  button.c3,
  li.c3,
  p.c3,
  a.c3,
  .c3 > * {
    font-size: var(--content-three-font-size-tablet);
  }

  button.c4,
  li.c4,
  p.c4,
  a.c4,
  .c4 > * {
    font-size: var(--content-four-font-size-tablet);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: var(--header-one-font-size-phone) !important;
    font-weight: 700;
    letter-spacing: 0.36px;
    margin: 0;
  }

  h2 {
    font-size: var(--header-two-font-size-phone) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  h3 {
    font-size: var(--header-three-font-size-phone) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  h4 {
    font-size: var(--header-four-font-size-phone) !important;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  button.c1,
  li.c1,
  p.c1,
  a.c1,
  .c1 > * {
    font-size: var(--content-one-font-size-phone);
  }

  button.c2,
  li.c2,
  p.c2,
  a.c2,
  .c2 > * {
    font-size: var(--content-two-font-size-phone);
  }

  button.c3,
  li.c3,
  p.c3,
  a.c3,
  .c3 > * {
    font-size: var(--content-three-font-size-phone);
  }

  button.c4,
  li.c4,
  p.c4,
  a.c4,
  .c4 > * {
    font-size: var(--content-four-font-size-phone);
  }
}

@media (max-width: 450px) {
  .c4 > * {
    font-size: var(--content-four-font-size-mini);
  }
}
