.merchandise {
  display: grid;
  gap: var(--usualGap);
  grid-template-columns: 1fr 1fr;
}

.withinfo {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
}

.merch {
  width: 100%;
  display: flex;
  gap: 0;
  cursor: pointer;
  position: relative;
}

.merch > span {
  width: 45%;
}

.merchname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.merchdescription {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.merchdetailsskeleton {
  width: 1000px;
  height: 312px;
}

.merchimage {
  width: 45%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.merchinfos {
  background-color: var(--Text);
  width: 55%;
  height: 100%;
  color: var(--Alternative);
  padding: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
  justify-content: space-between;
  overflow: hidden;
}

.merchtop {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
  width: 100%;
}

.merchbottom {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
}

.merchbottominfos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchstatus {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
}

.merchstatus.available {
  background-color: green;
}

.merchbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.merchdetail {
  display: flex;
  flex-direction: row;
  gap: var(--usualGap);
}

.merchdetailimages {
  width: 400px;
}

.merchdetailinfocontainer {
  flex: 6;
}

.merchdetailimages,
.merchdetailinfocontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.merchdetailinfo {
  width: 100%;
  padding: 20px 100px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  gap: var(--usualGap);
  display: flex;
  flex-direction: column;
}

.merchdetailheader {
  width: 100%;
  text-align: left;
}

.merchdetailimage {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.merchdetailpricing {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
}

.merchcosts {
  font-size: 1.25rem;
  font-weight: 500;
}

.merchcostextra {
  font-size: 1rem;
  font-weight: 200;
}

.merchsize {
  font-size: 1rem;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.merchdetailavailability {
  display: flex;
  gap: var(--usualGap);
  align-items: center;
}

.merchandiseplaceholder {
  display: none;
}

.merchandiseplaceholder.show {
  display: block;
}

.merch.full > span {
  width: 100%;
}

.merchskeleton {
  width: 100%;
  height: 225px;
}

.merchimageloader {
  width: 100%;
  height: 100%;
}

@media (max-width: 1040px) {
  .merch {
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }

  .merch > span,
  .merch.full > span {
    width: 100%;
  }

  .merchskeleton {
    width: 100%;
  }

  .merchandise {
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .merchinfos {
    width: 100%;
  }

  .merchimage {
    width: 100%;
  }

  .merchimageloader {
    height: 100%;
    aspect-ratio: 1 / 1;
  }

  .merchdetailinfo {
    padding: 20px 20px;
  }

  .merchdetailsskeleton {
    width: 100%;
  }
}

@media only screen and (max-width: 999px) {
  .merchdetailinfo {
    padding: 20px 20px;
  }
}

@media (max-width: 800px) {
  .merchandise {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }

  .merchimageloader {
    height: 100%;
  }

  .merchdetail {
    flex-direction: column;
  }

  .merchdetailinfo {
    padding: 20px 0px;
  }

  .merchdetailimages {
    width: 100%;
  }
}

@media (max-width: 580px) {
  .merchandise {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
}

@media only screen and (max-width: 530px) {
  .merchdescription {
    line-clamp: 1;
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 400px) {
  .merchandise {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
