.firebaseimg {
  width: 250px;
  height: 250px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 28px;
}

.firebaseimgcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 28px;
  position: relative;
}

.firebaseimgcontainer.thumbnail {
  border: 2px solid var(--Text);
}

.firebaseimgloader {
  width: 2rem;
  height: 2rem;
  border: 4px solid var(--Text50);
  border-bottom-color: var(--Text);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.firebaseimgtasks {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: var(--Text);
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
  height: 40px;
  padding-right: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  gap: 10px;
}
