.requestpart {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
}

.getmemberrequestcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getmemberrequest {
  height: 40px;
}

.downloadloader {
  animation: activedownload 3s infinite;
}

.downloadplaceholder {
  width: 20px;
}

@keyframes activedownload {
  50% {
    opacity: 0.33;
  }
}
