.smallArticle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--usualGap);
  cursor: pointer;
}

.smallArticle > * {
  width: 100%;
}

.smallArticleMainInfo {
  display: flex;
  height: 125px;
  gap: var(--usualGap);
  align-self: stretch;
}

.articleDate {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color: var(--TextAlternative);
}

.smallArticlePhotoLoader,
.smallArticlePhoto {
  height: 125px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.smallArticleContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-editor-main {
  overflow: hidden !important;
}

.smallArticleContentText {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.smallArticleContentTextWithDate {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 800px) {
  .smallArticlePhotoLoader,
  .smallArticleMainInfo,
  .smallArticlePhoto {
    height: 100px;
  }
}
