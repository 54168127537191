.topBarWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 9000;
  background: var(--Text);
}

.topBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--Text);
  padding: 20px;
}

.homeIcon {
  width: 40px;
  height: 40px;
}

.menuMobile {
  height: 40px;
  padding: 0px 2.5px;
  flex-direction: column;
  gap: 5.5px;
  cursor: pointer;
}

.menuBarAll {
  border-radius: 5px;
  background: var(--Alternative);
  width: 30px;
  height: 3px;
  transition: var(--transitionTime);
}

.menuBarBig {
  width: 35px;
}

.showMenu #menuBar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.showMenu #menuBar2 {
  opacity: 0;
}

.showMenu #menuBar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

@media screen and (min-width: 800px) {
  .menuMobile {
    display: none !important;
  }
  .topBar {
    width: auto;
  }
}
