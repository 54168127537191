.smallArticleContainer {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: var(--bigGap);
}

.thumbnailpicture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 600px) {
  .smallArticleContainer {
    grid-template-columns: 1fr;
  }
}
