.personModalPicture {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  position: relative;
}

.personalInformation {
  top: 0;
  position: absolute;
  width: calc(2 * 100% + var(--usualGap));
  display: flex;
  flex-direction: column;
  background-color: var(--Text);
  color: var(--Alternative);
  padding: var(--bigGap);
  gap: var(--usualGap);
  box-sizing: border-box;
}

.memberfunction {
  opacity: 0.5;
}

.openToMiddle {
  left: 50%;
  transform: translate(-50%);
}

.openToMiddle.personalInformation::before {
  left: 50%;
  transform: translate(-50%);
}

.openToLeft {
  right: 0;
}

.openToLeft.personalInformation::before {
  left: auto;
  right: 0;
}

.personalInformation::before {
  position: absolute;
  top: -10px;
  left: 0;
  width: calc(50% - var(--usualGap) / 2);
  background-color: var(--Text);
  content: '';
  height: var(--usualGap);
}
