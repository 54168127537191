.section,
.sectionContent {
  display: flex;
  flex-direction: column;
  gap: var(--bigGap);
  width: 100%;
}

.alignment > * {
  text-align: justify;
  text-align-last: none;
}
