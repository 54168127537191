.infobar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-width: 200px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 40px;
  text-align: center;
}

.infobar.info {
  background-color: var(--Text);
  color: var(--Alternative);
}

.infobar.error {
  background-color: orangered;
  color: var(--Text);
}
