.App {
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.noLinkDecoration {
  text-decoration: none;
}

.topSpacer {
  height: 80px;
}

.mainView {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.content {
  width: 1000px;
}

.smallIcon {
  width: 20px;
  height: 20px;
  background-color: var(--Text);
}
