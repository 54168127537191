.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--usualGap);
}

.formInput {
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 27px;
  width: 100%;
  outline: none;
  color: var(--Text);
  padding: 20px 60px 20px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.actionButton {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  padding: 10px 20px;
}

.area {
  height: 200px;
  resize: none;
}

.contactsecuritydescription {
  font-family: var(--standard-font-family);
  color: var(--text-color);
  font-size: 0.75rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
}

.contactsecuritydescription a {
  text-decoration: none;
  font-family: var(--standard-font-family);
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
