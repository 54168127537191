.errorboundary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 40px;
  padding: 20px 200px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.errorboundaryheader,
.errorboundaryfooter {
  width: 100%;
}

.errorboundaryheadercontent {
  font-size: var(--header-one-font-size-desktop);
  font-family: var(--header-font-family);
  font-weight: 500;
  color: var(--Text);
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
  line-break: auto;
  -ms-line-break: auto;
  -webkit-line-break: auto;
}

.errorboundarydescription {
  font-size: var(--content-two-font-size-desktop);
  font-family: var(--content-font-family);
  color: var(--Text);
  font-weight: 400;
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
  line-break: auto;
  -ms-line-break: auto;
  -webkit-line-break: auto;
}

.errorboundarydescription a {
  color: var(--Text);
  text-decoration: none;
}

@media (max-width: 1200px) {
  .errorboundary {
    padding: 20px;
  }

  .errorboundaryheadercontent {
    font-size: var(--header-one-font-size-tablet);
  }

  .errorboundarydescription {
    font-size: var(--content-two-font-size-tablet);
  }
}

@media (max-width: 1000px) {
  .errorboundaryheadercontent {
    font-size: var(--header-one-font-size-phone);
  }

  .errorboundarydescription {
    font-size: var(--content-two-font-size-phone);
  }
}
