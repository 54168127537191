.mapPinIcon {
  mask-image: url(/public/icons/mapPinIcon.svg);
  -webkit-mask-image: url(/public/icons/mapPinIcon.svg);
  mask-size: contain;
}

.phoneIcon {
  mask-image: url(/public/icons/phoneIcon.svg);
  -webkit-mask-image: url(/public/icons/phoneIcon.svg);
  mask-size: contain;
}

.emailIcon {
  mask-image: url(/public/icons/mailIcon.svg);
  -webkit-mask-image: url(/public/icons/mailIcon.svg);
  mask-size: contain;
}

.contactCombination {
  display: flex;
  gap: var(--usualGap);
}

.contactContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--bigGap);
}

@media screen and (min-width: 1000px) {
  .contactContent {
    display: grid !important;
    grid-template-columns: auto auto;
    align-items: center !important;
    width: 100%;
  }
}

.heroSection + .contactContent {
  margin-top: 220px;
}
