.menu {
  position: absolute;
  top: var(--heightOfShownMenu);
  padding: 10px 0;
  flex-direction: column;
  background: var(--TextAlternative);
  gap: 5px;
  z-index: -1;
  transition: top var(--transitionTime) ease-in-out;
  width: 100vw;
  left: 0px;
}

.menu.showMenu {
  top: 80px;
}

.menuPunkt {
  text-align: center;
  color: var(--Alternative);
}

.menuLine {
  margin-top: 5px;
  width: 200px;
  height: 0;
  border: 0.5px var(--Alternative) solid;
}

@media screen and (min-width: 800px) {
  .menu {
    top: auto;
    position: relative;
    flex-direction: row;
    z-index: 1;
    background: transparent;
    padding: 0;
    gap: 20px;
    justify-content: flex-end !important;
    padding: 0 20px 0 0;
  }
  .menuLine {
    display: none;
  }
  .menu.showMenu {
    top: 0;
  }
  .action {
    background-color: var(--Action);
    color: var(--Text);
    border-radius: 20px;
    padding: 5px 18.5px;
  }
}
