.pageloadercontainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.pageloader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  height: 200px;
  width: 300px;
}
