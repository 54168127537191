.topbarcontainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 9000;
  background: var(--Text);
}

.topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--Text);
  padding: 20px;
}

.topbaricon {
  width: 40px;
  height: 40px;
  text-decoration: none;
}
