.member {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
  position: relative;
}

.memberskeleton {
  height: 150px;
}

.sizingHelper {
  position: relative;
}

.teamMemberPicture {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  cursor: pointer;
}

@media only screen and (min-width: 530px) {
  .memberskeleton {
    height: 240px;
  }
}
