.vereinsraum {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
  width: 100%;
}

.alignmentspecial {
  text-align: justify;
  text-align-last: justify;
}
