.statutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;
  width: 100%;
}

.statutes > * {
  border-radius: 15px;
}

.statutesdocument {
  box-shadow: 0 0 40px 0 var(--Text25);
  overflow: hidden;
}

.statutesdocument.hidden {
  display: none;
}

.separator {
  height: 20px;
}

.statutescontrols {
  position: sticky;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
}

.pagecontrol {
  padding: 10px;
  background-color: var(--Action);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.pagecontrollabel {
  color: var(--Text);
  min-width: 25px;
  text-align: center;
}

.pagecontrolbutton {
  cursor: pointer;
  background-color: var(--Action);
  border: none;
  margin-top: 4px;
  box-sizing: border-box;
}

.downloadcontrol {
  height: 40px;
  width: 40px;
  background-color: var(--Action);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

.downloadcontrol.nonevisible {
  background-color: transparent;
}

.statutesskeleton {
  height: 715px;
  width: 505px;
}

.textLayer {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}

@media (max-width: 1000px) {
  .statutescontrols {
    gap: 20px;
  }

  .statutesskeleton {
    height: 420px;
    width: 297px;
  }
}

@media (max-width: 500px) {
  .statutescontrols {
    gap: 20px;
  }

  .statutesskeleton {
    height: 336px;
    width: 238px;
  }
}
