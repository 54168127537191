.inputcontainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.custominput,
.customtextarea {
  width: 100%;
  background-color: transparent;
  border: none;
  font-family: var(--standard-font-family);
  color: var(--Text);
  font-size: var(--content-one-font-size-desktop);
  height: 20px;
}

.custominput:focus,
.customtextarea:focus {
  outline: none;
}

.custominput::placeholder,
.customtextarea::placeholder {
  color: var(--Text50);
  opacity: 1; /* Firefox */
}

.custominput::-ms-input-placeholder,
.customtextarea.custominput::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--Text50);
}

.customtextarea {
  resize: none;
}

input,
textarea {
  padding: 2px;
}

.hinticoncontainer {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
}

.hinticoncontainer.multiline {
  align-items: end;
  padding-bottom: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.hinticon {
  width: 1.5rem;
  height: 1.5rem;
}

.hinticon.error {
  color: red;
}

.hinticon.success {
  color: green;
}

.custominput:-webkit-autofill,
.custominput:-webkit-autofill:hover,
.custominput:-webkit-autofill:focus .custominput:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media (max-width: 1200px) {
  .custominput,
  .customtextarea {
    font-size: var(--content-one-font-size-tablet);
  }

  .hinticon {
    width: var(--content-one-font-size-tablet);
    height: var(--content-one-font-size-tablet);
  }
}

@media (max-width: 1000px) {
  .custominput,
  .customtextarea {
    font-size: var(--content-one-font-size-phone);
  }

  .hinticon {
    width: var(--content-one-font-size-phone);
    height: var(--content-one-font-size-phone);
  }
}
