*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#calendar {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 385px;
  height: 515px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.calendarloadercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarloader {
  width: 385px;
  height: 515px;
}

.header {
  position: relative;
  height: 50px;
  width: 385px;
  background: var(--Action);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.left,
.right {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  cursor: pointer;
}

.left {
  border-width: 7.5px 10px 7.5px 0;
  border-color: transparent var(--Text) transparent transparent;
  left: 20px;
}

.right {
  border-width: 7.5px 0 7.5px 10px;
  border-color: transparent transparent transparent var(--Text);
  right: 20px;
}

.month {
  /*overflow: hidden;*/
  opacity: 0;
}

.month.new {
  -webkit-animation: fadeIn 1s ease-out;
  -moz-animation: fadeIn 1s ease-out;
  animation: fadeIn 1s ease-out;
  opacity: 1;
}

.month.in.next {
  -webkit-animation: moveFromTopFadeMonth 0.4s ease-out;
  -moz-animation: moveFromTopFadeMonth 0.4s ease-out;
  animation: moveFromTopFadeMonth 0.4s ease-out;
  opacity: 1;
}

.month.out.next {
  -webkit-animation: moveToTopFadeMonth 0.4s ease-in;
  -moz-animation: moveToTopFadeMonth 0.4s ease-in;
  animation: moveToTopFadeMonth 0.4s ease-in;
  opacity: 1;
}

.month.in.prev {
  -webkit-animation: moveFromBottomFadeMonth 0.4s ease-out;
  -moz-animation: moveFromBottomFadeMonth 0.4s ease-out;
  animation: moveFromBottomFadeMonth 0.4s ease-out;
  opacity: 1;
}

.month.out.prev {
  -webkit-animation: moveToBottomFadeMonth 0.4s ease-in;
  -moz-animation: moveToBottomFadeMonth 0.4s ease-in;
  animation: moveToBottomFadeMonth 0.4s ease-in;
  opacity: 1;
}

.week {
  background: var(--Text);
}

.day {
  display: inline-block;
  width: 55px;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  cursor: default;
  background: var(--Text);
  position: relative;
  z-index: 100;
  color: var(--Alternative);
}

.day.other {
  color: var(--TextAlternative30);
}

.day.today {
  color: var(--Action);
}

.day-name {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--TextAlternative50);
  letter-spacing: 0.7px;
}

.day-number {
  letter-spacing: 1.5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstGreenSecondGreen {
  background: linear-gradient(
    to bottom right,
    green 0%,
    green 50%,
    green 50%,
    green 100%
  );
}

.firstRedSecondRed {
  background: linear-gradient(
    to bottom right,
    red 0%,
    red 50%,
    red 50%,
    red 100%
  );
}

.firstGreenSecondRed {
  background: linear-gradient(
    to bottom right,
    green 0%,
    green 50%,
    red 50%,
    red 100%
  );
}

.firstRedSecondGreen {
  background: linear-gradient(
    to bottom right,
    red 0%,
    red 50%,
    green 50%,
    green 100%
  );
}

.previousDays {
  background: linear-gradient(
    to bottom right,
    gray 0%,
    gray 50%,
    gray 50%,
    gray 100%
  );
}

.arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -2px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent var(--TextAlternative) transparent;
  transition: all 0.7s ease;
}

.legend {
  position: absolute;
  bottom: 0;
  background: var(--Text);
  line-height: 30px;
  width: 100%;
}

.entry {
  position: relative;
  padding: 0 0 0 25px;
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
  background: transparent;
  color: var(--Alternative);
}

.entry:after {
  position: absolute;
  content: '';
  height: 5px;
  width: 5px;
  top: 13px;
  left: 14px;
}

.entry.red:after {
  background: red;
}
.entry.green:after {
  background: green;
}

@-webkit-keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(-30%) scale(0.95);
    -moz-transform: translateY(-30%) scale(0.95);
    transform: translateY(-30%) scale(0.95);
  }
}
@-moz-keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
@keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}

@-webkit-keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(30%) scale(0.95);
    -moz-transform: translateY(30%) scale(0.95);
    transform: translateY(30%) scale(0.95);
  }
}
@-moz-keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    transform: translateY(30%);
  }
}
@keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    transform: translateY(30%);
  }
}

@-webkit-keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%) scale(0.95);
    -moz-transform: translateY(30%) scale(0.95);
    transform: translateY(30%) scale(0.95);
  }
}
@-moz-keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    transform: translateY(30%);
  }
}
@keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    transform: translateY(30%);
  }
}

@-webkit-keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%) scale(0.95);
    -moz-transform: translateY(-30%) scale(0.95);
    transform: translateY(-30%) scale(0.95);
  }
}
@-moz-keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
@keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}

@media (max-width: 468px) {
  #calendar {
    width: 315px;
    height: 470px;
  }

  .calendarloader {
    width: 315px;
    height: 470px;
  }

  .header {
    height: 50px;
    width: 315px;
  }

  .day {
    width: 45px;
    padding: 5px;
  }

  .day-number {
    font-size: 20px;
  }
}

@media (max-width: 370px) {
  #calendar {
    width: 280px;
    height: 420px;
  }

  .calendarloader {
    width: 280px;
    height: 420px;
  }

  .header {
    height: 50px;
    width: 280px;
  }

  .day {
    width: 40px;
    padding: 5px;
  }

  .day-name {
    font-size: 8px;
  }

  .day-number {
    font-size: 18px;
    height: 30px;
  }
}
