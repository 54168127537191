.thumbnailpicture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.thumbnailpicture > span {
  width: 100%;
}

.thumbnailpictureloader {
  width: 100%;
  height: 250px;
}

@media (max-width: 800px) {
  .thumbnailpictureloader {
    height: 200px;
  }
}
