.adminscreen {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
