.impressum {
  display: flex;
  flex-direction: column;
  gap: var(--usualGap);
}

.impressum > h3 {
  text-align: left;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  line-break: auto;
  -ms-line-break: auto;
  -webkit-line-break: auto;
}

.codinglaneimg {
  max-width: 128px;
  border-radius: 20px;
}

.codinglaneinfos {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.designedby {
  display: flex;
  flex-direction: row;
  gap: var(--usualGap);
  justify-content: left;
  align-items: center;
}
